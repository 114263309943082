import React from "react";
import PropTypes from "prop-types";

const AuthFormSwitch = ({
  register,
  registrationSwitchCopy,
  loginSwitchCopy,
  switchView,
  registrationSwitchLink,
  loginSwitchLink,
}) => {
  const switchCopy = register ? registrationSwitchCopy : loginSwitchCopy;
  const switchLink = register ? registrationSwitchLink : loginSwitchLink;
  // If there is no switchLink to split on, the entire switchCopy will be a link
  const splitSwitchCopy = switchCopy.split(switchLink);

  return (
    <div className="auth-subtitle">
      <span dangerouslySetInnerHTML={{ __html: splitSwitchCopy[0] || "" }} />
      <button
        className="auth-subtitle__cta"
        id="auth-view-switch"
        onClick={() => {
          switchView();
        }}>
        {switchLink || ""}
      </button>
      <span dangerouslySetInnerHTML={{ __html: splitSwitchCopy[1] || "" }} />
    </div>
  );
};

AuthFormSwitch.propTypes = {
  register: PropTypes.bool,
  registrationSwitchCopy: PropTypes.string,
  loginSwitchCopy: PropTypes.string,
  switchView: PropTypes.func,
  registrationSwitchLink: PropTypes.string,
  loginSwitchLink: PropTypes.string,
};

export default AuthFormSwitch;
