import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AuthActions from "actions/auth-actions";
import ClosableModal from "components/source/shared/closable-modal";
import StandaloneAuthForm from "components/source/shared/auth_form/standalone-wrapper";
import { authModalPropType } from "../../../propTypes/authentication-prop-types";

export class ModalAuthWrapper extends Component {
  static propTypes = {
    authModal: authModalPropType,
    closeAuthModal: PropTypes.func.isRequired,
  };

  getHeightAndWidth() {
    return this.props.authModal?.isFullScreen ? { height: "100%", width: "100%" } : { width: "660px" };
  }

  render() {
    return (
      <ClosableModal
        // We want the auth modal to supersede but not discard other modals, so we don't want to override
        // the displayedModal state value but instead rely on a separate value altogether.
        isOpen={this.props.authModal?.isOpen || false}
        onRequestClose={this.props.closeAuthModal}
        // We need the accessibility button (position: fixed) to break out of the modal, so we can accomplish this by
        // replacing the transform attribute with flexbox to center the modal.
        // @see https://stackoverflow.com/questions/25824749/why-webkit-transform-translate3d0-0-0-messes-up-with-fixed-childs
        additionalStyles={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            top: "auto",
            left: "auto",
            MozTransform: "none",
            MsTransform: "none",
            WebkitTransform: "none",
            transform: "none",
            margin: "auto",
            padding: "auto",
            ...this.getHeightAndWidth(),
          },
        }}
        optionalClass="auth-form-modal modal-close-circle"
        overlayClassName="auth-modal-overlay">
        <StandaloneAuthForm />
      </ClosableModal>
    );
  }
}

const mapStateToProps = state => ({
  authModal: state.authModal,
});

const mapDispatchToProps = dispatch => ({
  closeAuthModal: () => dispatch(AuthActions.closeAuthModal(true)),
});

const AuthModal = connect(mapStateToProps, mapDispatchToProps)(ModalAuthWrapper);
export default AuthModal;
