import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import AuthFormClose from "components/source/shared/auth_form/close";
import AuthFormError from "components/source/shared/auth_form/error";
import { authFormConstants } from "rtr-constants/auth";

const { Views } = authFormConstants;
class AuthFormChallengeForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    additionalClass: PropTypes.string,
    showClose: PropTypes.bool,
    closeModal: PropTypes.func,
    password: PropTypes.string,
    provider: PropTypes.string,
    onSubmit: PropTypes.func,
    errorCopy: PropTypes.func,
    setError: PropTypes.func,
    error: PropTypes.string,
    register: PropTypes.bool,
    requestNewChallengeCode: PropTypes.func,
  };

  state = {
    challengeCode: "",
    hasResentChallengeCode: false,
  };

  handleChallengeCodeChange = e => {
    const challengeCode = e.target.value;
    this.setState({ challengeCode });
    if (challengeCode.length === 6) {
      e.target.setSelectionRange(0, 0);
      e.target.blur();
      this.props.onSubmit({
        username: this.props.email,
        challenge_code: challengeCode,
        provider: this.props.provider,
        ...(!!this.props.password && { password: this.props.password }),
      });
    }
  };

  handleChallengeFocus = () => {
    if (this.props.error && this.state.challengeCode.length === 6) {
      this.setState({ challengeCode: "" });
      this.props.setError(null);
    }
  };

  requestNewCode() {
    const params = {
      email: this.props.email,
      context: this.props.register ? Views.registration : Views.login,
      ...(!!this.props.password && this.props.register && { password: this.props.password }),
    };
    this.props.requestNewChallengeCode(params, this.handleSuccess, this.handleFail);
  }

  handleSuccess = () => {
    this.setState({
      challengeCode: "",
      hasResentChallengeCode: true,
    });
    this.props.setError(null);
  };

  handleFail = errorData => {
    this.props.setError(this.props.errorCopy(errorData));
  };

  render() {
    const { email, additionalClass, showClose, closeModal, error } = this.props;
    const { challengeCode, hasResentChallengeCode } = this.state;
    /* cSpell:ignore Didn */
    return (
      <div className="challenge-form">
        <h3 className="challenge-header">Please Verify Your Account.</h3>
        <p className="challenge-body">
          We&apos;ve emailed {hasResentChallengeCode ? "another" : "a"} one-time code to{" "}
          <span className="universal-medium--semibold">{email}.</span> Enter it below to finish signing in.
        </p>
        <form name="challenge-form">
          <input
            className={classNames("challenge-input", { "challenge-error": error })}
            value={challengeCode}
            onChange={this.handleChallengeCodeChange}
            onFocus={this.handleChallengeFocus}
            maxLength="6"></input>
        </form>
        <div className="challenge-body">
          {!!error && <AuthFormError additionalClass={additionalClass} error={error} />}
        </div>
        <div className="challenge-footer">
          <p className="universal-medium--semibold">Didn&apos;t get it?</p>
          <button
            className="cta-btn--link challenge-footer--btn-request-new-code universal-underline universal-small--semibold"
            onClick={() => this.requestNewCode()}>
            Request a new code
          </button>
          , check your spam folder, or give us a call at{" "}
          <a className="universal-underline universal-small--semibold" href="tel:1-800-509-0842">
            1-800-509-0842
          </a>
        </div>
        {showClose && <AuthFormClose closeModal={closeModal} />}
      </div>
    );
  }
}

export default AuthFormChallengeForm;
