import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const AuthFormRegistrationError = ({ additionalClass, switchView }) => {
  const className = classNames("error", {
    [`${additionalClass}__error`]: additionalClass,
  });

  return (
    <div className={className}>
      We had a problem processing your request. If you already have an account, please try&nbsp;
      <a className="error_link" onClick={switchView}>
        signing in
      </a>
      .
    </div>
  );
};

AuthFormRegistrationError.propTypes = {
  additionalClass: PropTypes.string,
  switchView: PropTypes.func,
};

export default AuthFormRegistrationError;
