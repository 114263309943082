import React from "react";
import PropTypes from "prop-types";

import { authFormConstants } from "rtr-constants/auth";

const { Views } = authFormConstants;

const AuthFormTitle = ({ hideTitle, register, copy }) => {
  const authType = register ? Views.registration : Views.login;
  const title = copy?.[authType]?.desktop?.title ?? "";
  const mobileTitle = copy?.[authType]?.mobile?.title ?? "";

  if (hideTitle) {
    return null;
  }

  return (
    <div>
      <div className="dek-one auth-title mq__no-mobile" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="dek-one auth-title mq__mobile" dangerouslySetInnerHTML={{ __html: mobileTitle }} />
    </div>
  );
};

AuthFormTitle.propTypes = {
  hideTitle: PropTypes.bool,
  register: PropTypes.bool,
  copy: PropTypes.object,
};

export default AuthFormTitle;
