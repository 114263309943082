import React from "react";
import PropTypes from "prop-types";

import AtomTagline from "components/source/atoms/atom-tagline";
import { authFormConstants } from "rtr-constants/auth";

const { Views } = authFormConstants;

const AuthFormTagline = ({ copy, register }) => {
  const authType = register ? Views.registration : Views.login;
  const tagline = copy?.[authType]?.desktop?.dek ?? "";
  const mobileTagline = copy?.[authType]?.mobile?.dek ?? "";

  if (!tagline && !mobileTagline) {
    return null;
  }

  return <AtomTagline text={tagline} mobileText={mobileTagline} />;
};

AuthFormTagline.propTypes = {
  copy: PropTypes.object,
  register: PropTypes.bool,
};

export default AuthFormTagline;
