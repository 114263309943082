import React from "react";
import AuthFormChallengeForm from "components/source/shared/auth_form/challenge-form";
import AuthFormLegacySwitch from "components/source/shared/auth_form/legacy-switch";
import AuthFormForm from "components/source/shared/auth_form/form";
import AuthFormSubtitle from "components/source/shared/auth_form/subtitle";
import AuthFormSwitch from "components/source/shared/auth_form/switch";
import AuthFormThirdPartyAuth from "components/source/shared/auth_form/third-party-auth";
import AuthFormTagline from "components/source/shared/auth_form/tag-line";
import AuthFormTitle from "components/source/shared/auth_form/title";
import classNames from "classnames";
import PropTypes from "prop-types";

const DefaultAuthForm = ({
  additionalClass,
  authUrl,
  canRenderStorefront,
  challenge,
  closeModal,
  closeText,
  copy,
  destination,
  disableSubmit,
  email,
  error,
  errorCopy,
  handleActionLogging,
  hideTitle,
  legacyStyling,
  loginDestination,
  loginSwitchCopy,
  loginSwitchLink,
  onOptionalSuccess,
  onRequestClose,
  onSubmit,
  optionalLoginCopy,
  password,
  provider,
  register,
  registerDestination,
  registrationError,
  registrationSwitchCopy,
  registrationSwitchLink,
  requestNewChallengeCode,
  setError,
  showClose,
  showFacebook,
  showGoogle,
  showOptionalLogin,
  switchView,
}) => {
  const className = classNames("auth-form", additionalClass, {
    registration: register,
    login: !register,
  });

  if (challenge) {
    return (
      <AuthFormChallengeForm
        email={email}
        password={password}
        provider={provider}
        additionalClass={additionalClass}
        showClose={showClose}
        closeModal={closeModal}
        onSubmit={params => onSubmit(params)}
        errorCopy={errorCopy}
        setError={setError}
        error={error}
        register={register}
        requestNewChallengeCode={requestNewChallengeCode}
      />
    );
  } else {
    return (
      <div data-test-id="auth-form" className={className}>
        <AuthFormTagline copy={copy} register={register} />
        <AuthFormTitle hideTitle={hideTitle} register={register} copy={copy} />
        <AuthFormSubtitle register={register} copy={copy} />
        {!legacyStyling && (
          <AuthFormSwitch
            register={register}
            registrationSwitchCopy={registrationSwitchCopy}
            registrationSwitchLink={registrationSwitchLink}
            loginSwitchCopy={loginSwitchCopy}
            loginSwitchLink={loginSwitchLink}
            switchView={switchView}
          />
        )}
        {canRenderStorefront && (
          <AuthFormForm
            registerDestination={registerDestination}
            loginDestination={loginDestination}
            register={register}
            destination={destination}
            onSubmit={params => onSubmit(params)}
            additionalClass={additionalClass}
            switchView={switchView}
            copy={copy}
            closeText={closeText}
            onRequestClose={onRequestClose}
            showOptionalLogin={showOptionalLogin}
            optionalLoginCopy={optionalLoginCopy}
            onOptionalSuccess={onOptionalSuccess}
            logInferAction={data => handleActionLogging(data, "infer")}
            disableSubmit={disableSubmit}
            authUrl={authUrl}
            setError={setError}
            error={error}
            registrationError={registrationError}
            showTermsAndConditions={true}
          />
        )}
        <AuthFormThirdPartyAuth
          legacyStyling={legacyStyling}
          register={register}
          showFacebook={showFacebook}
          showGoogle={showGoogle}
        />
        {legacyStyling && (
          <AuthFormLegacySwitch
            register={register}
            registrationSwitchCopy={registrationSwitchCopy}
            loginSwitchCopy={loginSwitchCopy}
            switchView={switchView}
          />
        )}
      </div>
    );
  }
};

DefaultAuthForm.propTypes = {
  additionalClass: PropTypes.string,
  authUrl: PropTypes.string.isRequired,
  canRenderStorefront: PropTypes.bool.isRequired,
  challenge: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  copy: PropTypes.object,
  destination: PropTypes.string,
  disableSubmit: PropTypes.bool,
  email: PropTypes.string,
  error: PropTypes.string,
  errorCopy: PropTypes.string,
  handleActionLogging: PropTypes.func.isRequired,
  hideTitle: PropTypes.bool,
  legacyStyling: PropTypes.bool,
  loginDestination: PropTypes.string,
  loginSwitchCopy: PropTypes.string,
  loginSwitchLink: PropTypes.string,
  onOptionalSuccess: PropTypes.func,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  optionalLoginCopy: PropTypes.string,
  password: PropTypes.string,
  provider: PropTypes.string,
  register: PropTypes.bool,
  registerDestination: PropTypes.string,
  registrationError: PropTypes.bool,
  registrationSwitchCopy: PropTypes.string,
  registrationSwitchLink: PropTypes.string,
  requestNewChallengeCode: PropTypes.func,
  setError: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  showFacebook: PropTypes.bool,
  showGoogle: PropTypes.bool,
  showOptionalLogin: PropTypes.bool,
  switchView: PropTypes.func.isRequired,
};

export default DefaultAuthForm;
