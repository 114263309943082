import { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

import AuthActions from "actions/auth-actions";
import { withFeatureFlags, Flags } from "components/source/hoc/with-feature-flags";
import { authFormConstants, COOKIES, EVENTS_CUSTOM_DOM } from "rtr-constants";
import AuthForm from "components/source/shared/auth_form";
import {
  isRegistration,
  getPostAuthenticationDestination,
  onGoogleOneTapClose,
  onGoogleOneTapInit,
} from "helpers/auth-helpers";
import { TrafficSource } from "helpers/traffic-source";
import { AuthFormPropTypes } from "components/propTypes/auth-form-prop-type";
import { isSSR } from "helpers/client-server-helper";

const { PageNames, Views } = authFormConstants;

// Instead of moving all of this logic into the AuthForm, I wanted to create a temporary place to parody the Backbone
// code but with React paradigms. Once the flag is flipped, and we feel comfortable, we can move these methods into AuthForm
export class StandaloneAuthWrapper extends Component {
  static propTypes = {
    ...AuthFormPropTypes, // don't do this! linters don't work when you do
    newCustomerOfferText: PropTypes.object, // provided by connect below
    switchView: PropTypes.func,
  };

  componentDidMount() {
    const { featureFlags, oneTap, createGoogleOneTapAuthenticationAttempt } = this.props;
    const clientId = oneTap?.clientId;

    if (featureFlags[Flags.GOOGLE_ONE_TAP]) {
      onGoogleOneTapInit(clientId, createGoogleOneTapAuthenticationAttempt);
    }

    window.addEventListener(EVENTS_CUSTOM_DOM.OAUTH_COMPLETE, this.handleOauthCompleteEventFromPopupWindow);
  }

  componentWillUnmount() {
    const { featureFlags } = this.props;

    if (featureFlags[Flags.GOOGLE_ONE_TAP]) {
      onGoogleOneTapClose();
    }

    window.removeEventListener(EVENTS_CUSTOM_DOM.OAUTH_COMPLETE, this.handleOauthCompleteEventFromPopupWindow);
  }

  authCopy = () => {
    const { authModal, newCustomerOfferText: cmsProps } = this.props;

    if (!cmsProps) return;

    const loginDek = cmsProps.loginDek || "";
    const loginTitle = authModal?.title || cmsProps.loginTitle || "Sign In";
    const loginSubtitle = authModal?.subtitle || cmsProps.loginSubtitle || "Sign in to access your account";
    const loginButtonText = cmsProps.loginButtonText || "Sign In";
    const registrationDek = cmsProps.registrationDek || "";
    const registrationTitle = authModal?.title || cmsProps.registrationTitle || "Join Now";
    const registrationSubtitle = authModal?.subtitle || cmsProps.registrationSubtitle || "";
    const registrationButtonText = cmsProps.registrationButtonText || "Join Now";
    const { buttonSubtext } = cmsProps; // In the future, we can change this in the CMS to give options for mobile/desktop and registration/login

    return {
      registration: {
        desktop: {
          title: registrationTitle,
          subtitle: registrationSubtitle,
          buttonText: registrationButtonText,
          buttonSubtext: buttonSubtext,
          dek: registrationDek,
        },
        mobile: {
          title: cmsProps.registrationTitleMobile || registrationTitle,
          subtitle: cmsProps.registrationSubtitleMobile || registrationSubtitle,
          buttonText: cmsProps.registrationButtonTextMobile || registrationButtonText,
          buttonSubtext: buttonSubtext,
          dek: cmsProps.registrationDekMobile || registrationDek,
        },
      },
      login: {
        desktop: {
          title: loginTitle,
          subtitle: loginSubtitle,
          buttonText: loginButtonText,
          dek: loginDek,
        },
        mobile: {
          title: cmsProps.loginTitleMobile || loginTitle,
          subtitle: cmsProps.loginSubtitleMobile || loginSubtitle,
          buttonText: cmsProps.loginButtonTextMobile || loginButtonText,
          dek: cmsProps.loginDekMobile || loginDek,
        },
      },
    };
  };

  closeAuthModal = hideModal => {
    const { closeAuthModal } = this.props;

    closeAuthModal(hideModal);
  };

  handleOauthCompleteEventFromPopupWindow = event => {
    const data = event?.detail;

    this.props.handleOauthComplete(data);
  };

  onSuccess = authResponse => {
    this.props.handlePostAuthenticationSideEffects(authResponse);
  };

  showFacebook = () => {
    if (this.props.authModal?.view === Views.registration) {
      if (isSSR()) return false;

      const params = this.props.cookies.get(COOKIES.CRM_DATA, { doNotParse: false });
      const hasFacebookUtmSource = params?.utm_source?.indexOf?.("facebook") >= 0;

      return (
        this.props.pageName === PageNames.PAID_LANDING || TrafficSource.isPaidSession() || hasFacebookUtmSource || false
      );
    }

    return true;
  };

  showGoogle = () => this.props.featureFlags[Flags.GOOGLE_OAUTH];

  switchView = view => {
    this.props.switchView(view);
  };

  render() {
    const { authModal } = this.props;
    return (
      <AuthForm
        additionalClass={this.props.additionalClass}
        authModal={authModal}
        closeText={this.props.closeText}
        cookies={this.props.cookies}
        copy={this.authCopy()}
        createAppleAuthenticationAttempt={this.props.createAppleAuthenticationAttempt}
        createAuthenticationAttempt={this.props.createAuthenticationAttempt}
        destination={getPostAuthenticationDestination(authModal)}
        hideStorefrontAuth={this.props.hideStorefrontAuth}
        hideTitle={this.props.hideTitle}
        legacyStyling
        loggingExtraObject={this.props.loggingExtraObject}
        loginDestination={this.props.loginDestination}
        loginSwitchCopy={this.props.loginSwitchCopy}
        loginSwitchLink={this.props.loginSwitchLink}
        onOptionalSuccess={this.props.onOptionalSuccess}
        onRequestClose={this.closeAuthModal}
        onSuccess={this.onSuccess}
        onSwitchView={this.switchView}
        optionalLoginCopy={this.props.optionalLoginCopy}
        register={isRegistration(authModal)}
        registerDestination={this.props.registerDestination}
        registrationSwitchCopy={this.props.registrationSwitchCopy}
        registrationSwitchLink={this.props.registrationSwitchLink}
        requestNewChallengeCode={this.props.requestNewChallengeCode}
        showFacebook={this.showFacebook()}
        showGoogle={this.showGoogle()}
        showOptionalLogin={this.props.showOptionalLogin}
        updateAuthModal={this.props.updateAuthModal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeAuthModal: hideModal => {
    dispatch(AuthActions.closeAuthModal(hideModal));
  },
  createAuthenticationAttempt: (targetUrl, params, onSuccess, onFailure) => {
    dispatch(AuthActions.createAuthenticationAttempt(targetUrl, params, onSuccess, onFailure));
  },
  createAppleAuthenticationAttempt: (data, onSuccess, onFailure) => {
    dispatch(AuthActions.createAppleAuthenticationAttempt(data, onSuccess, onFailure));
  },
  createGoogleOneTapAuthenticationAttempt: response => {
    dispatch(AuthActions.createGoogleOneTapAuthenticationAttempt(response));
  },
  handleOauthComplete: oauthComplete => {
    dispatch(AuthActions.handleOauthComplete(oauthComplete));
  },
  handlePostAuthenticationSideEffects: authResponse => {
    dispatch(AuthActions.handlePostAuthenticationSideEffects(authResponse));
  },
  requestNewChallengeCode: (params, onSuccess, onFailure) => {
    dispatch(AuthActions.requestNewChallengeCode(params, onSuccess, onFailure));
  },
  switchView: view => {
    dispatch(AuthActions.updateAuthModal({ view, errorMessage: null }));
  },
  updateAuthModal: data => {
    dispatch(AuthActions.updateAuthModal(data));
  },
});

const mapStateToProps = state => {
  return {
    authModal: state.authModal,
    displayedModal: state.displayedModal,
    newCustomerOfferText: state.newCustomerOfferText,
    pageType: state.pageType,
    oneTap: state.oneTap,
  };
};

export default compose(
  withCookies,
  // looks like these are on permanently
  withFeatureFlags(Flags.GOOGLE_OAUTH, Flags.GOOGLE_ONE_TAP),
  connect(mapStateToProps, mapDispatchToProps)
)(StandaloneAuthWrapper);
