import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import classNames from "classnames";

import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import constants from "rtr-constants";
import PasswordStrength from "@rtr/libpasswordstrength";
import GreenCheck from "images/green-check.svg";

const defaultValidationErrorMsg = constants.clientSideErrorMessages.formValidation.password;
const defaultLabel = constants.formLabels.password;

const passwordInputType = "password";
const textInputType = "text";
export const DEBOUNCE_PASSWORD_STRENGTH_WAIT = 300;

export default class AnimatedPasswordInput extends React.Component {
  static propTypes = {
    additionalClassName: PropTypes.string,
    error: PropTypes.string,
    focus: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlurCallback: PropTypes.func,
    onChangeCallback: PropTypes.func,
    onFocusCallback: PropTypes.func,
    onPasswordStrengthCallback: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    showPasswordStrength: PropTypes.bool,
    showCheckMark: PropTypes.bool,
    validationErrorMsg: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    additionalClassName: "",
    id: "password-input",
    label: defaultLabel,
    name: "password",
    onChangeCallback: () => {},
    required: true,
    validationErrorMsg: defaultValidationErrorMsg,
  };

  state = {
    inputType: passwordInputType,
    passwordStrength: "",
  };

  componentWillUnmount() {
    if (this.debouncePasswordStrength) {
      this.debouncePasswordStrength.cancel();
    }
  }

  showPasswordStrengthIndicator() {
    const { showPasswordStrength } = this.props;

    return showPasswordStrength;
  }

  onPasswordStrengthCallback = value => {
    if (this.props.onPasswordStrengthCallback) {
      this.props.onPasswordStrengthCallback(value);
    }
    this.setState({ passwordStrength: value.toLowerCase() });
  };

  debouncePasswordStrength = _.debounce(() => {
    if (this.value) {
      PasswordStrength.passwordEntropy(this.value, this.onPasswordStrengthCallback);
    }
  }, DEBOUNCE_PASSWORD_STRENGTH_WAIT);

  validPasswordStrength(value) {
    const strength = value.toUpperCase();
    return (
      strength !== PasswordStrength.PASSWORD_STRENGTH.PWNED && strength !== PasswordStrength.PASSWORD_STRENGTH.REJECTED
    );
  }

  validPassword(value) {
    // if we are not using the password strength indicator, the only invalid password is an empty one
    if (!this.showPasswordStrengthIndicator()) {
      return !!value;
    }

    // check the this.state.passwordStrength and current password for validity
    // onPasswordStrengthCallback will update this.state.passwordStrength
    return (
      this.validPasswordStrength(PasswordStrength.passwordEntropy(value)) &&
      this.validPasswordStrength(this.state.passwordStrength)
    );
  }

  changeInputType = event => {
    event.preventDefault();
    const newInputType = this.state.inputType === passwordInputType ? textInputType : passwordInputType;

    this.setState({
      inputType: newInputType,
    });

    // keep the focus on the password input despite the toggle, and
    // for mobile, keep the keyboard open on the toggle
    document.querySelector(`#${this.props.id}`)?.dispatchEvent?.(new Event("focus"));
  };

  onChangeCallback = (value, isValid) => {
    this.props.onChangeCallback(value, isValid);
    if (!this.props.showPasswordStrength) {
      return;
    } else if (!value) {
      this.setState({ passwordStrength: "" });
      return;
    }
    this.value = value;

    // don't wait for the callback for the initial password strength check
    const strength = PasswordStrength.passwordEntropy(value, null).toLowerCase();
    if (strength === PasswordStrength.PASSWORD_STRENGTH.REJECTED.toLowerCase()) {
      this.onPasswordStrengthCallback(PasswordStrength.PASSWORD_STRENGTH.REJECTED);
    } else {
      this.setState({ passwordStrength: strength });
      this.debouncePasswordStrength();
    }
  };

  renderPasswordStrength() {
    if (!this.showPasswordStrengthIndicator()) {
      return;
    }
    const strength = this.validPasswordStrength(this.state.passwordStrength)
      ? this.state.passwordStrength
      : PasswordStrength.PASSWORD_STRENGTH.REJECTED.toLowerCase();

    const passwordStrengthClass = "password-strength-indicator";
    const passwordStrengthClassNames = classNames(passwordStrengthClass, {
      [`${passwordStrengthClass}--${strength}`]: !!strength,
    });

    return <span data-test-id={`password-strength-${strength}`} className={passwordStrengthClassNames} />;
  }

  render() {
    const wrapperClassName = classNames("animated-password-input", {
      "with-check-mark": this.props.showCheckMark,
    });
    const toggleClassName = classNames("toggle-password", {
      "reb-icon_password_show": this.state.inputType === passwordInputType,
      "reb-icon_password_hide": this.state.inputType === textInputType,
    });
    // needed for jquery validation
    const hibp = this.state.passwordStrength === PasswordStrength.PASSWORD_STRENGTH.PWNED.toLowerCase();
    return (
      <div className={wrapperClassName} data-hibp={hibp}>
        <AnimatedTextInputWrapper
          id={this.props.id}
          name={this.props.name}
          additionalClassName={this.props.additionalClassName}
          type={this.state.inputType}
          label={this.props.label}
          validateInput={value => this.validPassword(value)}
          validationErrorMsg={this.props.validationErrorMsg}
          error={this.props.error}
          value={this.props.value}
          onBlurCallback={this.props.onBlurCallback}
          onChangeCallback={this.onChangeCallback}
          onFocusCallback={this.props.onFocusCallback}
          focus={this.props.focus}
          required={this.props.required}
          readOnly={this.props.readOnly}
        />
        {this.props.showCheckMark ? (
          <GreenCheck className="password-check-mark" />
        ) : (
          <button type="button" toggle={this.props.id} className={toggleClassName} onClick={this.changeInputType} />
        )}
        {this.renderPasswordStrength()}
      </div>
    );
  }
}
