import React from "react";
import PropTypes from "prop-types";

import { authFormConstants } from "rtr-constants/auth";

const { Views } = authFormConstants;

const AuthFormSubtitle = ({ copy, register }) => {
  const authType = register ? Views.registration : Views.login;
  const subtitle = copy?.[authType]?.desktop?.subtitle ?? "";
  const mobileSubtitle = copy?.[authType]?.mobile?.subtitle ?? "";

  return (
    <div className="auth-form-title__subtitle subtitle-field">
      <span className="mq__no-mobile" dangerouslySetInnerHTML={{ __html: subtitle }} />
      <span className="mq__mobile" dangerouslySetInnerHTML={{ __html: mobileSubtitle }} />
    </div>
  );
};

AuthFormSubtitle.propTypes = {
  copy: PropTypes.object,
  register: PropTypes.bool,
};

export default AuthFormSubtitle;
