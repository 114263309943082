import React from "react";

import AuthFormChallengeForm from "components/source/shared/auth_form/challenge-form";
import AuthFormForm from "components/source/shared/auth_form/form";
import AuthFormSwitch from "components/source/shared/auth_form/switch";
import AuthFormThirdPartyAuth from "components/source/shared/auth_form/third-party-auth";
import AuthFormTermsAndConditions from "components/source/shared/auth_form/terms-and-conditions";
import AuthFormForgotPassword from "components/source/shared/auth_form/forgot-password";
import MembershipProgressIndicator from "components/source/membership/onboarding/MembershipProgressIndicator";
import PropTypes from "prop-types";
import { authFormConstants } from "rtr-constants/auth";
import RtrImage from "../../shared/rtr-image";

const PlansAuthForm = ({
  additionalClass,
  authUrl,
  canRenderStorefront,
  challenge,
  closeModal,
  closeText,
  destination,
  disableSubmit,
  email,
  error,
  errorCopy,
  handleActionLogging,
  legacyStyling,
  loginDestination,
  onOptionalSuccess,
  onRequestClose,
  onSubmit,
  optionalLoginCopy,
  password,
  provider,
  register,
  registerDestination,
  registrationError,
  requestNewChallengeCode,
  setError,
  showFacebook,
  showGoogle,
  showOptionalLogin,
  switchView,
}) => {
  const divider = () => <div className={"divider"} />;
  const logInferAction = data => handleActionLogging(data, "infer");
  const termsAndConditions = () => {
    return register ? <AuthFormTermsAndConditions /> : <AuthFormForgotPassword logInferAction={logInferAction} />;
  };
  const headerCopy = () => {
    return register ? (
      <div className="header">
        <h2>Let&apos;s get renting!</h2>
        <p>Ready to step into the designer closet?</p>
        <p>Create an account below.</p>
      </div>
    ) : (
      <h2 className="header">Sign in for a better experience</h2>
    );
  };

  const buttonCopy = {
    [authFormConstants.Views.registration]: {
      desktop: {
        buttonText: "Next",
      },
      mobile: {
        buttonText: "Next",
      },
    },
    [authFormConstants.Views.login]: {
      desktop: {
        buttonText: "Sign In",
      },
      mobile: {
        buttonText: "Sign In",
      },
    },
  };

  if (challenge) {
    return (
      <AuthFormChallengeForm
        additionalClass={additionalClass}
        closeModal={closeModal}
        email={email}
        error={error}
        errorCopy={errorCopy}
        onSubmit={params => onSubmit(params)}
        password={password}
        provider={provider}
        register={register}
        requestNewChallengeCode={requestNewChallengeCode}
        setError={setError}
        showClose={true}
      />
    );
  } else {
    return (
      <div className="auth-form plans">
        <MembershipProgressIndicator
          currentIndex={1}
          paddingPercentageBetweenLines={1}
          showStepCount={true}
          totalSteps={2}
        />
        <RtrImage
          src={"https://cdn.rtrcdn.com/assets/imgs/11282022_Superbar_Offerings-Membership.svg"}
          alt={"icon"}
          key={"icon"}
        />
        {headerCopy()}
        {canRenderStorefront && (
          <AuthFormForm
            registerDestination={registerDestination}
            loginDestination={loginDestination}
            register={register}
            destination={destination}
            onSubmit={params => onSubmit(params)}
            additionalClass={additionalClass}
            switchView={switchView}
            copy={buttonCopy}
            closeText={closeText}
            onRequestClose={onRequestClose}
            showOptionalLogin={showOptionalLogin}
            optionalLoginCopy={optionalLoginCopy}
            onOptionalSuccess={onOptionalSuccess}
            logInferAction={logInferAction}
            disableSubmit={disableSubmit}
            authUrl={authUrl}
            setError={setError}
            error={error}
            registrationError={registrationError}
            showTermsAndConditions={false}
          />
        )}
        <AuthFormThirdPartyAuth
          legacyStyling={legacyStyling}
          register={register}
          showFacebook={showFacebook}
          showGoogle={showGoogle}
          usePlansStyling={true}
        />
        {termsAndConditions()}
        {divider()}
        <AuthFormSwitch
          additionalClass={additionalClass}
          register={register}
          registrationSwitchCopy={"Already have an account?"}
          registrationSwitchLink={"Sign In"}
          loginSwitchCopy={"Don't have an account?"}
          loginSwitchLink={"Join Now"}
          switchView={switchView}
        />
      </div>
    );
  }
};

PlansAuthForm.propTypes = {
  additionalClass: PropTypes.string,
  authUrl: PropTypes.string.isRequired,
  canRenderStorefront: PropTypes.bool.isRequired,
  challenge: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  destination: PropTypes.string,
  disableSubmit: PropTypes.bool,
  email: PropTypes.string,
  error: PropTypes.string,
  errorCopy: PropTypes.string,
  handleActionLogging: PropTypes.func.isRequired,
  legacyStyling: PropTypes.bool,
  loginDestination: PropTypes.string,
  onOptionalSuccess: PropTypes.func,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  optionalLoginCopy: PropTypes.string,
  password: PropTypes.string,
  provider: PropTypes.string,
  register: PropTypes.bool,
  registerDestination: PropTypes.string,
  registrationError: PropTypes.bool,
  requestNewChallengeCode: PropTypes.func,
  setError: PropTypes.func.isRequired,
  showFacebook: PropTypes.bool,
  showGoogle: PropTypes.bool,
  showOptionalLogin: PropTypes.bool,
  switchView: PropTypes.func.isRequired,
};

export default PlansAuthForm;
