import React from "react";
import PropTypes from "prop-types";
import AtomText from "components/source/atoms/atom-text";

class AtomTagline extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    taglineAttributes: PropTypes.shape({
      // Horizontal alignment – left, center, right
      horizontalAlign: PropTypes.string,
      // Changes text color – black or white
      theme: PropTypes.string,
      // Changes text treatment - bold or italic
      textTreatment: PropTypes.string,
      // Vertical alignment – top, middle, bottom
      verticalAlign: PropTypes.string,
    }),
    customClass: PropTypes.string,
    mobileText: PropTypes.string,
    mobileTaglineAttributes: PropTypes.object,
  };

  render() {
    var customClass = this.props.customClass ? this.props.customClass : "";
    return (
      <AtomText
        customClass={`atom-tagline ${customClass}`}
        text={this.props.text}
        textAttributes={this.props.taglineAttributes}
        mobileText={this.props.mobileText}
        mobileTextAttributes={this.props.mobileTaglineAttributes}
      />
    );
  }
}

export default AtomTagline;
