import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import ActionLogger from "action-logger";
import { ACCOUNT_TYPES, clientSideErrorMessages, StandardCheckoutConstants } from "rtr-constants";
import { authFormConstants } from "rtr-constants/auth";
import DefaultAuthForm from "./default-auth";
import PlansAuthForm from "./plans-auth";
import { AuthFormPropTypes } from "components/propTypes/auth-form-prop-type";
import { errorCodes, hasAssociatedErrorMessage, getErrorMessage, errorMessages } from "helpers/error-message-maps/auth";

const { resetPasswordError } = clientSideErrorMessages.passwordErrorMessage;
const { Views } = authFormConstants;

export class AuthFormComponent extends Component {
  static propTypes = AuthFormPropTypes;

  static appleSignInEvents = {
    APPLE_ID_SIGN_IN_ON_SUCCESS: "AppleIDSignInOnSuccess",
    APPLE_ID_SIGN_IN_ON_FAILURE: "AppleIDSignInOnFailure",
  };

  state = {
    challenge: false,
    email: "",
    password: "",
    error: null,
    loginAttempts: 0,
    lastLoginMethod: null,
    provider: "RTR",
  };

  componentDidMount() {
    const { APPLE_ID_SIGN_IN_ON_SUCCESS, APPLE_ID_SIGN_IN_ON_FAILURE } = this.constructor.appleSignInEvents;
    const lastLoginMethod = this.lastLoginMethod();

    this.setState({
      lastLoginMethod: lastLoginMethod,
    });

    ActionLogger.logAction({
      object_type: "auth_form",
      action: "loaded",
      lastLoginMethod: lastLoginMethod,
    });

    [APPLE_ID_SIGN_IN_ON_SUCCESS, APPLE_ID_SIGN_IN_ON_FAILURE].forEach(event => {
      document.addEventListener(event, this.handleAppleSignInCallback);
    });
  }

  componentWillUnmount() {
    const { APPLE_ID_SIGN_IN_ON_SUCCESS, APPLE_ID_SIGN_IN_ON_FAILURE } = this.constructor.appleSignInEvents;

    [APPLE_ID_SIGN_IN_ON_SUCCESS, APPLE_ID_SIGN_IN_ON_FAILURE].forEach(event => {
      document.removeEventListener(event, this.handleAppleSignInCallback);
    });
  }

  // Clears error state on modal open if modal was closed by clicking outside modal
  UNSAFE_componentWillReceiveProps() {
    this.setState({ error: null });
  }

  componentDidUpdate(prevProps) {
    this.handleOauthErrorUpdate(prevProps);
  }

  handleOauthErrorUpdate = prevProps => {
    const { authModal } = this.props;
    const { authModal: prevAuthModal } = prevProps;

    if (!this.state.error && authModal?.errorMessage && prevAuthModal?.errorMessage !== authModal.errorMessage) {
      this.setState({ error: authModal.errorMessage });
    }
  };

  lastLoginMethod() {
    return this.props.cookies.get("lastLoginMethod");
  }

  handleActionLogging = (event, type = "log") => {
    const action = {
      ...this.props.loggingExtraObject,
      object_type: "user",
      action: event,
    };
    const actions = {
      log: () => ActionLogger.logAction(action),
      infer: () => ActionLogger.inferAction(action),
    };
    actions[type]();
  };

  switchView = () => {
    const newView = this.props.register ? Views.login : Views.registration;

    this.handleActionLogging(`${newView}_click`);
    this.setState({ loginAttempts: 0, error: null, registrationError: false });

    if (!this.props.onSwitchView) {
      return;
    }
    this.props.onSwitchView(newView);
  };

  setError = error => {
    this.setState({ error });
  };

  errorCopy = (data = {}) => {
    const { responseJSON } = data;
    const { challenge, loginAttempts } = this.state;
    const { register } = this.props;

    if (!register && !challenge && loginAttempts >= 3) {
      return resetPasswordError;
    }

    // Ideally we would return unique error keys from Users and let client code determine which error message
    // to display to prevent accidentally displaying BE error information, but we aren't there yet so this is
    // a first step
    if (hasAssociatedErrorMessage(responseJSON?.reason)) {
      return getErrorMessage(responseJSON.reason);
    }

    if (responseJSON?.reason && responseJSON?.message) {
      return responseJSON?.message;
    }

    return errorMessages[errorCodes.UNEXPECTED_ERROR];
  };

  resetAuthModalState = () => {
    this.props.updateAuthModal({
      errorMessage: null,
    });
  };

  closeModal = () => {
    this.resetAuthModalState();

    this.setState({ challenge: false, provider: "RTR", error: null }, () => {
      this.props.onRequestClose();
    });
  };

  authUrl() {
    const context = this.props.register ? "register" : "login";
    const authDestination = this.props[context + "Destination"] || this.props.destination;
    const authUrl = `/account/${context}`;
    return authDestination ? `${authUrl}?destination=${authDestination}` : authUrl;
  }

  onSubmit = params => {
    this.setState(state => {
      return {
        email: params.username,
        password: params.password,
        disableSubmit: true,
        error: null,
        loginAttempts: state.loginAttempts + 1,
        provider: params.provider,
      };
    });

    this.props.createAuthenticationAttempt(this.authUrl(), params, this.handleSuccessfulLogin, this.handleFailedLogin);
  };

  /**
   * @param {CustomEvent} event
   * @param {{ authorization: { code: string, id_token: string, state: string } } | { error: string }} event.detail Apple payload
   * @memberof AppleSignInButton
   * @see https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
   * @description If the callback succeeds, the event object's 'detail' object will contain an 'authorization' object.
   * If the callback fails, the event object's 'detail' object will contain an 'error' property with an error code
   */
  handleAppleSignInCallback = event => {
    const data = event.detail;

    this.props.createAppleAuthenticationAttempt(data, this.handleSuccessfulLogin, this.handleFailedLogin);
  };

  handleSuccessfulLogin = authResponse => {
    if (typeof this.props.onSuccess === "function") {
      this.props.onSuccess(authResponse);
    }
  };

  handleFailedLogin = data => {
    const { responseJSON } = data;
    const { challenge } = this.state;
    if (responseJSON?.reason === errorCodes.CHALLENGED && responseJSON.meta?.email) {
      const challengeEmail = responseJSON.meta?.email;
      const challengeProvider = responseJSON.provider;

      this.setState({
        challenge: true,
        email: challengeEmail,
        provider: challengeProvider,
        disableSubmit: false,
      });
    } else if (responseJSON?.reason === errorCodes.CHALLENGED) {
      this.setState({
        error: getErrorMessage(),
        disableSubmit: false,
      });
    } else if (responseJSON?.meta?.userAccountType === ACCOUNT_TYPES.LIMITED) {
      this.setState({
        disableSubmit: true,
        error: StandardCheckoutConstants.FINISH_LIMITED_ACCOUNT_PROMPT,
      });
    } else if (this.props.register && !challenge) {
      this.setState({
        disableSubmit: false,
        registrationError: true,
      });
    } else {
      this.setState({
        error: this.errorCopy(data),
        disableSubmit: false,
      });
    }
  };

  canRenderStorefront() {
    return !this.props.hideStorefrontAuth || this.state.lastLoginMethod === "storefront";
  }

  render() {
    const {
      additionalClass,
      showClose,
      copy,
      closeText,
      register,
      hideTitle,
      // This is always true, so we can get rid of the AuthFormSwitch component in cleanup ticket and remove this prop
      // @see {https://renttherunway.jira.com/browse/PSEC-1058}
      legacyStyling,
      registrationSwitchCopy,
      loginSwitchCopy,
      registrationSwitchLink,
      loginSwitchLink,
      destination,
      loginDestination,
      registerDestination,
      requestNewChallengeCode,
      onRequestClose,
      showOptionalLogin,
      optionalLoginCopy,
      onOptionalSuccess,
      showFacebook,
      showGoogle,
    } = this.props;
    const authUrl = this.authUrl();
    const { DisplayStyle } = authFormConstants;

    switch (this.props.authModal?.displayStyle) {
      case DisplayStyle.PLANS_PAGE:
        return (
          <PlansAuthForm
            additionalClass={"plans-auth-form"}
            authUrl={authUrl}
            canRenderStorefront={this.canRenderStorefront()}
            challenge={this.state.challenge}
            closeModal={this.closeModal}
            closeText={closeText}
            copy={copy}
            destination={destination}
            disableSubmit={this.state.disableSubmit}
            email={this.state.email}
            error={this.state.error}
            errorCopy={this.errorCopy()}
            handleActionLogging={this.handleActionLogging}
            hideTitle={hideTitle}
            legacyStyling={legacyStyling}
            loginDestination={loginDestination}
            loginSwitchCopy={loginSwitchCopy}
            loginSwitchLink={loginSwitchLink}
            onOptionalSuccess={onOptionalSuccess}
            onRequestClose={onRequestClose}
            onSubmit={this.onSubmit}
            optionalLoginCopy={optionalLoginCopy}
            password={this.state.password}
            provider={this.state.provider}
            register={register}
            registerDestination={registerDestination}
            registrationError={this.state.registrationError}
            registrationSwitchCopy={registrationSwitchCopy}
            registrationSwitchLink={registrationSwitchLink}
            requestNewChallengeCode={requestNewChallengeCode}
            setError={this.setError}
            showClose={showClose}
            showFacebook={showFacebook}
            showGoogle={showGoogle}
            showOptionalLogin={showOptionalLogin}
            switchView={this.switchView}
          />
        );
      case DisplayStyle.DEFAULT:
      default:
        return (
          <DefaultAuthForm
            additionalClass={additionalClass}
            authUrl={authUrl}
            canRenderStorefront={this.canRenderStorefront()}
            challenge={this.state.challenge}
            closeModal={this.closeModal}
            closeText={closeText}
            copy={copy}
            destination={destination}
            disableSubmit={this.state.disableSubmit}
            email={this.state.email}
            error={this.state.error}
            errorCopy={this.errorCopy()}
            handleActionLogging={this.handleActionLogging}
            hideTitle={hideTitle}
            legacyStyling={legacyStyling}
            loginDestination={loginDestination}
            loginSwitchCopy={loginSwitchCopy}
            loginSwitchLink={loginSwitchLink}
            onOptionalSuccess={onOptionalSuccess}
            onRequestClose={onRequestClose}
            onSubmit={this.onSubmit}
            optionalLoginCopy={optionalLoginCopy}
            password={this.state.password}
            provider={this.state.provider}
            register={register}
            registerDestination={registerDestination}
            registrationError={this.state.registrationError}
            registrationSwitchCopy={registrationSwitchCopy}
            registrationSwitchLink={registrationSwitchLink}
            requestNewChallengeCode={requestNewChallengeCode}
            setError={this.setError}
            showClose={showClose}
            showFacebook={showFacebook}
            showGoogle={showGoogle}
            showOptionalLogin={showOptionalLogin}
            switchView={this.switchView}
          />
        );
    }
  }

  static defaultProps = {
    loggingExtraObject: {},
    loginSwitchCopy: "Don’t have an account? Join now.",
    registrationSwitchCopy: "Already have an account? Sign in.",
    copy: {
      login: {
        desktop: {
          buttonText: "Sign In",
        },
        mobile: {
          buttonText: "Sign In",
        },
      },
      registration: {
        desktop: {
          buttonText: "Join Now",
        },
        mobile: {
          buttonText: "Join Now",
        },
      },
    },
    legacyStyling: false,
  };
}

export default compose(connect())(AuthFormComponent);
