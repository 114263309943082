import React from "react";

const AuthFormTermsAndConditions = () => (
  <div className="terms-of-service">
    By registering, I accept the <br />
    <a href="/pages/termsofservice" target="_blank">
      Terms of Service
    </a>{" "}
    and{" "}
    <a href="/privacy" target="_blank">
      Privacy Policy
    </a>
    .
  </div>
);

export default AuthFormTermsAndConditions;
