import { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";

import AuthFormButtons from "components/source/shared/auth_form/buttons";
import AnimatedEmailInput from "components/source/shared/animated_inputs/animated-email-input";
import AuthFormError from "components/source/shared/auth_form/error";
import AuthFormForgotPassword from "components/source/shared/auth_form/forgot-password";
import AnimatedPasswordInput from "components/source/shared/animated_inputs/animated-password-input";
import AuthFormRegistrationError from "components/source/shared/auth_form/registration-error";
import AuthFormTermsAndConditions from "components/source/shared/auth_form/terms-and-conditions";

import { clientSideErrorMessages } from "rtr-constants";
import PasswordStrength from "@rtr/libpasswordstrength";
import { getUserEmail, userDataPropType, withUserData } from "../../hoc/with-user-data";

const defaultEmailValidationErrorMsg = clientSideErrorMessages.formValidation.email;
const defaultPasswordValidationErrorMsg = clientSideErrorMessages.formValidation.password;
const passwordInputLabel = "Password";
const { weakPasswordError } = clientSideErrorMessages.passwordErrorMessage;
const { pwdedPasswordError } = clientSideErrorMessages.passwordErrorMessage;

class AuthFormForm extends Component {
  static propTypes = {
    register: PropTypes.bool,
    destination: PropTypes.string,
    additionalClass: PropTypes.string,
    switchView: PropTypes.func,
    copy: PropTypes.object,
    closeText: PropTypes.string,
    onRequestClose: PropTypes.func,
    showOptionalLogin: PropTypes.bool,
    optionalLoginCopy: PropTypes.string,
    onOptionalSuccess: PropTypes.func,
    disableSubmit: PropTypes.bool,
    onSubmit: PropTypes.func,
    logInferAction: PropTypes.func,
    authUrl: PropTypes.string,
    setError: PropTypes.func,
    error: PropTypes.string,
    registrationError: PropTypes.bool,
    showTermsAndConditions: PropTypes.bool.isRequired,
    userData: userDataPropType,
  };

  state = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
  };

  componentDidUpdate(prevProps) {
    // Initialize state with the identified user's email (consider if the application state has been updated async or if
    // the component state has not been updated yet with the current email)
    const userEmail = getUserEmail(this.props.userData);
    if ((userEmail && getUserEmail(prevProps.userData) !== userEmail) || (userEmail && !this.state.email)) {
      this.setState({ email: userEmail });
    }

    if (prevProps.register !== this.props.register) {
      this.resetErrors();
    }
  }

  resetErrors() {
    this.setState({
      passwordError: "",
      passwordStrength: null,
    });

    if (_.isEmpty(this.state.email)) {
      this.setState({
        emailError: "",
      });
    }
  }

  validate = () => {
    return this.state.emailIsValid && this.state.passwordIsValid;
  };

  handleSubmit = event => {
    window.scrollTo(0, 0);
    event.preventDefault();

    if (!this.validate()) return;

    const params = {
      username: this.state.email,
      provider: "RTR",
      password: this.state.password,
    };

    this.props.onSubmit(params);
  };

  handleEmailChange = (email, isValid) => {
    this.setState({
      email: email,
      emailIsValid: isValid,
    });

    if (isValid) {
      this.setState({
        emailError: null,
      });
    }
  };

  handleEmailBlur = (email, isValid) => {
    this.handleEmailChange(email, isValid);

    if (!isValid) {
      this.setState({
        emailIsValid: false,
        emailError: defaultEmailValidationErrorMsg,
      });
    }
  };

  handlePasswordBlur = (password, isValid) => {
    this.handlePasswordChange(password, isValid);
    if (this.props.register) {
      this.handleRegistrationFormPaswordBlur(password, isValid);
    } else {
      this.handleAuthFormPaswordBlur(password, isValid);
    }
  };

  handleRegistrationFormPaswordBlur(password, isValid) {
    if (_.isEmpty(password)) {
      this.setState({
        passwordIsValid: false,
        passwordError: this.getPasswordErrorMessage(password),
      });
      this.props.setError(null);
    } else if (!isValid) {
      const strength = this.state.passwordStrength;
      const error =
        strength === PasswordStrength.PASSWORD_STRENGTH.PWNED.toLowerCase() ? pwdedPasswordError : weakPasswordError;
      this.setState({
        passwordIsValid: false,
        passwordError: this.getPasswordErrorMessage(password),
      });
      this.props.setError(error);
    } else {
      this.props.setError(null);
    }
  }

  handleAuthFormPaswordBlur(password, isValid) {
    if (!isValid) {
      this.setState({
        passwordIsValid: false,
        passwordError: this.getPasswordErrorMessage(password),
      });
    }
  }

  handlePasswordChange = (password, isValid) => {
    this.setState({
      password: password,
      passwordIsValid: isValid,
    });

    if (isValid) {
      this.setState({
        passwordError: null,
      });
    }
  };

  getPasswordErrorMessage(password) {
    return _.isEmpty(password) ? defaultPasswordValidationErrorMsg : passwordInputLabel;
  }

  onPasswordStrengthCallback = value => {
    const strength = value.toLowerCase();
    if (this.state.passwordStrength !== strength) {
      this.setState({
        passwordStrength: strength,
        passwordIsValid: this.validPasswordStrength(value),
      });
    }
  };

  validPasswordStrength(value) {
    const strength = value?.toUpperCase();
    return (
      strength !== PasswordStrength.PASSWORD_STRENGTH.PWNED && strength !== PasswordStrength.PASSWORD_STRENGTH.REJECTED
    );
  }

  renderTermsAndConditions() {
    if (!this.props.showTermsAndConditions) {
      return;
    }

    return this.props.register ? (
      <AuthFormTermsAndConditions />
    ) : (
      <AuthFormForgotPassword logInferAction={this.props.logInferAction} />
    );
  }

  render() {
    const {
      additionalClass,
      switchView,
      copy,
      closeText,
      onRequestClose,
      showOptionalLogin,
      optionalLoginCopy,
      onOptionalSuccess,
      destination,
      register,
      disableSubmit,
      authUrl,
      error,
      registrationError,
    } = this.props;

    const formName = register ? "registration-form" : "login-form";
    const inputKey = register ? "register" : "login";

    return (
      <>
        {!!error && <AuthFormError additionalClass={additionalClass} error={error} />}
        {registrationError && (
          <AuthFormRegistrationError
            registrationError={registrationError}
            additionalClass={additionalClass}
            switchView={switchView}
          />
        )}
        <form
          id={formName}
          name={formName}
          className="auth-form__inputs"
          action={authUrl}
          onSubmit={this.handleSubmit}
          method="post"
          acceptCharset="UTF-8">
          <input type="hidden" name="destination" value={destination} />
          <AnimatedEmailInput
            label="Email"
            id="username"
            value={this.state.email}
            error={this.state.emailError}
            onBlurCallback={this.handleEmailBlur}
            onChangeCallback={this.handleEmailChange}
            required={true}
          />
          <AnimatedPasswordInput
            label="Password"
            id="password"
            error={this.state.passwordError}
            onPasswordStrengthCallback={register ? this.onPasswordStrengthCallback : null}
            showPasswordStrength={!!register}
            onBlurCallback={this.handlePasswordBlur}
            onChangeCallback={this.handlePasswordChange}
            key={inputKey}
            required={true}
          />
          {this.renderTermsAndConditions()}
          <AuthFormButtons
            copy={copy}
            register={register}
            closeText={closeText}
            onRequestClose={onRequestClose}
            showOptionalLogin={showOptionalLogin}
            optionalLoginCopy={optionalLoginCopy}
            onOptionalSuccess={onOptionalSuccess}
            isValid={this.validate()}
            disableSubmit={disableSubmit}
          />
        </form>
      </>
    );
  }
}

export default withUserData(AuthFormForm);
