import { Component } from "react";
import PropTypes from "prop-types";

import { authFormConstants } from "rtr-constants/auth";
import AppleSignInButton from "components/source/shared/apple-sign-in-button";
import OauthButton from "components/source/shared/oauth-button";

const { Views } = authFormConstants;

class AuthFormThirdPartyAuth extends Component {
  static propTypes = {
    legacyStyling: PropTypes.bool,
    register: PropTypes.bool,
    showFacebook: PropTypes.bool,
    showGoogle: PropTypes.bool,
    usePlansStyling: PropTypes.bool,
  };

  canRenderFacebook() {
    // always show Facebook for the login view
    return !this.props.register || this.props.showFacebook;
  }

  canRenderGoogle() {
    // always show Google for the login view
    return !this.props.register || this.props.showGoogle;
  }

  renderSocialSeparator() {
    if (this.props.legacyStyling && (this.canRenderFacebook() || this.canRenderGoogle())) {
      return (
        <div className="social-login-separator">
          <p className="title">or</p>
        </div>
      );
    }
  }

  renderApple() {
    const color = this.props.register || this.props.usePlansStyling ? "white" : "black";
    const key = this.props.register ? Views.registration : Views.login;
    const type = this.props.register ? "continue" : "sign in";
    const border = this.props.usePlansStyling ? "square" : "round";

    return <AppleSignInButton color={color} key={key} type={type} border={border} />;
  }

  render() {
    const includeIcon = !this.props.usePlansStyling;
    return (
      <div className="third-party-login">
        {this.renderSocialSeparator()}
        {/*{this.canRenderFacebook() && (*/}
        {/*  <OauthButton includeIcon={includeIcon} isRegistration={this.props.register} provider="facebook" />*/}
        {/*)}*/}
        {this.canRenderGoogle() && (
          <OauthButton
            includeIcon={includeIcon}
            id="signin-google"
            isRegistration={this.props.register}
            provider="google"
          />
        )}
        {this.renderApple()}
      </div>
    );
  }
}

export default AuthFormThirdPartyAuth;
