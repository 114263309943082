import React from "react";
import PropTypes from "prop-types";

// TODO: This should live on the modal/parent, not in the auth component
const AuthFormClose = ({ closeModal }) => {
  return <div className="reb-close_x close" title="Close (Esc)" onClick={closeModal}></div>;
};

AuthFormClose.propTypes = {
  closeModal: PropTypes.func,
};

export default AuthFormClose;
