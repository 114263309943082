import React from "react";
import PropTypes from "prop-types";

import { authFormConstants } from "rtr-constants/auth";
import { getIsOneTrustDisableButtonOverrideEnabled } from "helpers/auth-helpers";
import {
  flagsAndExperimentNames,
  flagsAndExperimentsPropType,
  withOnLoadFlagsAndExperiments,
} from "components/source/hoc/with-flags-and-experiments";

const { Views } = authFormConstants;

const AuthFormButtons = ({
  copy,
  register,
  closeText,
  onRequestClose,
  showOptionalLogin,
  optionalLoginCopy,
  onOptionalSuccess,
  isValid,
  disableSubmit,
  flagsAndExperiments,
}) => {
  const isButtonDisabledOverrideKillSwitchFlagEnabled =
    flagsAndExperiments?.[flagsAndExperimentNames.CONCO_STOREFRONT_CONCO_1995_REMOVE_SIGNIN_DISABLE_BUTTON_KILL_SWITCH];
  const isOneTrustDisableButtonOverrideEnabled = getIsOneTrustDisableButtonOverrideEnabled(
    Boolean(register),
    isButtonDisabledOverrideKillSwitchFlagEnabled
  );
  const authType = register ? Views.registration : Views.login;
  const buttonText = copy?.[authType]?.desktop?.buttonText;
  const mobileButtonText = copy?.[authType]?.mobile?.buttonText ?? "";
  const buttonSubtext = copy?.[authType]?.desktop?.buttonSubtext ?? "";
  const mobileButtonSubtext = copy?.[authType]?.mobile?.buttonSubtext ?? "";

  const disableButton = isOneTrustDisableButtonOverrideEnabled ? false : !isValid || disableSubmit;
  const canRenderButtonSubtext = !!buttonSubtext || !!mobileButtonSubtext;
  const canRenderCloseButton = !!closeText;

  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/no-static-element-interactions  */
  /* eslint-disable jsx-a11y/click-events-have-key-events  */
  return (
    <div className="submit-button">
      <button
        className="btn mq__no-mobile"
        id="auth-submit-desktop"
        name="auth-submit-desktop"
        disabled={disableButton}
        dangerouslySetInnerHTML={{ __html: buttonText }}
      />
      <button
        className="btn mq__mobile"
        id="auth-submit-mobile"
        name="auth-submit-mobile"
        disabled={disableButton}
        dangerouslySetInnerHTML={{ __html: mobileButtonText }}
      />
      {canRenderButtonSubtext && (
        <div>
          <div className="button-subtext mq__no-mobile" dangerouslySetInnerHTML={{ __html: buttonSubtext }} />
          <div className="button-subtext mq__mobile" dangerouslySetInnerHTML={{ __html: mobileButtonSubtext }} />
        </div>
      )}
      {canRenderCloseButton && (
        <a className="btn-secondary btn-close" onClick={onRequestClose}>
          {closeText}
        </a>
      )}
      {showOptionalLogin && (
        <div className="link">
          <button
            className="optional-login"
            onClick={() => {
              onOptionalSuccess();
            }}>
            {optionalLoginCopy}
          </button>
        </div>
      )}
    </div>
  );
};

AuthFormButtons.propTypes = {
  copy: PropTypes.object,
  register: PropTypes.bool,
  closeText: PropTypes.string,
  onRequestClose: PropTypes.func,
  showOptionalLogin: PropTypes.bool,
  optionalLoginCopy: PropTypes.string,
  onOptionalSuccess: PropTypes.func,
  isValid: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  flagsAndExperiments: flagsAndExperimentsPropType,
};

export default withOnLoadFlagsAndExperiments(
  flagsAndExperimentNames.CONCO_STOREFRONT_CONCO_1995_REMOVE_SIGNIN_DISABLE_BUTTON_KILL_SWITCH
)(AuthFormButtons);
