import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const AuthFormError = ({ additionalClass, error }) => {
  const className = classNames("error", {
    [`${additionalClass}__error`]: additionalClass,
  });

  return <div className={className}>{error}</div>;
};

AuthFormError.propTypes = {
  additionalClass: PropTypes.string,
  error: PropTypes.string,
};

export default AuthFormError;
