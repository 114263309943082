import { Cookies } from "react-cookie";
import PropTypes from "prop-types";

import { featureFlagsPropType } from "components/source/hoc/with-feature-flags";

export const AuthFormPropTypes = {
  additionalClass: PropTypes.string,
  authModal: PropTypes.shape({
    callback: PropTypes.func,
    errorMessage: PropTypes.string,
    isFullScreen: PropTypes.bool,
    isOpen: PropTypes.bool,
    mainClass: PropTypes.string,
    source: PropTypes.string,
    subtitle: PropTypes.string,
    view: PropTypes.string,
  }),
  closeText: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  copy: PropTypes.object, // shapeOf,
  createAppleAuthenticationAttempt: PropTypes.func,
  createAuthenticationAttempt: PropTypes.func,
  destination: PropTypes.string,
  dispatch: PropTypes.func,
  featureFlags: featureFlagsPropType,
  // @deprecated — prop is not passed so it is always undefined
  hideStorefrontAuth: PropTypes.bool,
  hideTitle: PropTypes.bool,
  legacyStyling: PropTypes.bool,
  loginDestination: PropTypes.string,
  loggingExtraObject: PropTypes.object,
  loginSwitchCopy: PropTypes.string,
  loginSwitchLink: PropTypes.string,
  onOptionalSuccess: PropTypes.func,
  onRequestClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onSwitchView: PropTypes.func,
  optionalLoginCopy: PropTypes.string,
  register: PropTypes.bool,
  registerDestination: PropTypes.string,
  registrationSwitchCopy: PropTypes.string,
  registrationSwitchLink: PropTypes.string,
  requestNewChallengeCode: PropTypes.func,
  showClose: PropTypes.bool,
  showFacebook: PropTypes.bool,
  showGoogle: PropTypes.bool,
  showOptionalLogin: PropTypes.bool,
  updateAuthModal: PropTypes.func,
};
