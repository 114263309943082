import React from "react";
import PropTypes from "prop-types";

import { authFormConstants } from "rtr-constants/auth";

const { Views } = authFormConstants;

const AuthFormLegacySwitch = ({ register, registrationSwitchCopy, loginSwitchCopy, switchView }) => {
  const switchCopy = register ? registrationSwitchCopy : loginSwitchCopy;
  const dataSwitchToCopy = register ? Views.login : Views.registration;

  return (
    <p className="switch-link">
      <button
        className="underline"
        id="auth-view-switch"
        onClick={() => {
          switchView();
        }}
        data-switchto={dataSwitchToCopy}>
        {switchCopy}
      </button>
    </p>
  );
};

AuthFormLegacySwitch.propTypes = {
  register: PropTypes.bool,
  registrationSwitchCopy: PropTypes.string,
  loginSwitchCopy: PropTypes.string,
  switchView: PropTypes.func,
};

export default AuthFormLegacySwitch;
