import React from "react";
import PropTypes from "prop-types";

const AuthFormForgotPassword = ({ logInferAction }) => (
  <a
    className="forgot-password"
    href="/account/forgot_password"
    onClick={() => {
      logInferAction("forgot_password");
    }}
    target="_blank">
    Forgot password?
  </a>
);

AuthFormForgotPassword.propTypes = {
  logInferAction: PropTypes.func,
};

export default AuthFormForgotPassword;
