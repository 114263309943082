import React from "react";
import PropTypes from "prop-types";

import AnimatedTextInputWrapper from "components/source/shared/animated-text-input-wrapper";
import constants from "rtr-constants";
import EmailIcon from "images/sprites/reb/svg/email-us.svg";

const emailValidator = constants.validations.email;
const defaultValidationErrorMsg = constants.clientSideErrorMessages.formValidation.email;
const defaultLabel = constants.formLabels.email;

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  additionalClassName: PropTypes.string,
  label: PropTypes.string,
  validationErrorMsg: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  focus: PropTypes.bool,
  handleInputChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  renderIcon: PropTypes.bool,
  setParentReference: PropTypes.func,
  disabled: PropTypes.bool,
  onBlurCallback: PropTypes.func,
  onChangeCallback: PropTypes.func,
};

const defaultProps = {
  id: "email-input",
  name: "email",
  additionalClassName: "",
  label: defaultLabel,
  validationErrorMsg: defaultValidationErrorMsg,
  renderIcon: false,
};

export default class AnimatedEmailInput extends React.Component {
  constructor(props) {
    super(props);
  }

  validEmail(value) {
    return value && !!value.match(emailValidator);
  }

  renderIcon() {
    if (this.props.renderIcon) {
      return <EmailIcon />;
    }
    return false;
  }

  render() {
    return (
      <AnimatedTextInputWrapper
        id={this.props.id}
        name={this.props.name}
        additionalClassName={this.props.additionalClassName}
        type={"email"}
        label={this.props.label}
        validateInput={value => this.validEmail(value)}
        validationErrorMsg={this.props.validationErrorMsg}
        error={this.props.error}
        value={this.props.value}
        onBlurCallback={this.props.onBlurCallback}
        onChangeCallback={this.props.onChangeCallback}
        focus={this.props.focus}
        required={this.props.required}
        readOnly={this.props.readOnly}
        icon={this.renderIcon()}
        setParentReference={this.props.setParentReference}
        disabled={this.props.disabled}
      />
    );
  }
}

AnimatedEmailInput.propTypes = propTypes;
AnimatedEmailInput.defaultProps = defaultProps;
