import React from "react";
import PropTypes from "prop-types";

import { appendScript } from "helpers/dom-helpers";

export default class AppleSignInButton extends React.Component {
  static propTypes = {
    border: PropTypes.oneOf(["rounded", "square"]),
    color: PropTypes.oneOf(["black", "white"]),
    type: PropTypes.oneOf(["continue", "sign in"]),
  };

  static defaultProps = {
    color: "black",
    type: "sign in",
  };

  state = {
    loaded: typeof AppleID !== "undefined" && !!AppleID, // Preset state to if AppleID is already available
  };

  componentDidMount() {
    this.loadLibrary();
  }

  loadLibrary = () => {
    // If we've loaded the library before, don't attempt
    if (this.isAppleIDAvailable()) {
      return this.initializeButton();
    }

    // 3rd-party integration
    appendScript({
      async: true,
      src: "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
      onload: () => this.onLibraryLoad(),
    });
  };

  onLibraryLoad = () => {
    this.initializeButton();
    this.setState({ loaded: true });
  };

  initializeButton = () => {
    // Apple does not allow apps to register 'localhost' and IP addresses and
    // will only allow us to add up to 10 redirect URLs, so the ones we registered
    // are prod, stage, qa1 ... qa8 testing this in other environments will not work
    // bc the redirect_uri will not be recognized
    const uri = location.origin;
    const state = Math.random().toString(36); // generate random string

    AppleID.auth.init({
      clientId: "com.renttherunway.siwa",
      scope: "name email",
      redirectURI: `${uri}/account/auth/apple/callback`,
      usePopup: true,
      state: state, // TODO: save this value and use to validate redirect request
    });
  };

  isAppleIDAvailable() {
    return this.state.loaded && typeof AppleID !== "undefined";
  }

  render() {
    const { border, color, type } = this.props;
    const borderRadius = border === "square" ? 0 : 15;

    return (
      <button
        id="appleid-signin"
        data-color={color}
        data-border="true"
        data-type={type}
        className="btn-apple"
        disabled={!this.isAppleIDAvailable()}
        data-border-radius={borderRadius}></button>
    );
  }
}
