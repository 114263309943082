import { isSSR } from "helpers/client-server-helper";

/**
 * Helper to create a new script tag and append it to the body of the DOM
 * @param {Object} attributes HTML script tag attributes {@see https://www.w3schools.com/tags/tag_script.asp}
 * @returns {HTMLScriptElement | null}
 */
export const appendScript = attributes => {
  if (isSSR()) return null;

  const script = document.createElement("script");

  for (const key in attributes) {
    if (Object.prototype.hasOwnProperty.call(attributes, key)) {
      script[key] = attributes[key];
    }
  }

  document.body.appendChild(script);

  return script;
};

/**
 * Helper function that uses the MutationObserver api to listen to changes to the DOM. A good use case for this is when
 * DOM elements are rendered by third-party sdks and you need a way to observe changes to those elements. By default
 * the subtree and attribute options are false so we don't unintentionally listen to the entire page's tree.
 * @param {string} selector css selector to query
 * @param {HTMLElement} target to perform queries on and observe
 * @param {MutationObserverInit} observerOptions the second param to MutationObserver.observe()
 * @see {https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver}
 */
export const waitForElement = (
  selector,
  target = document.body,
  observerOptions = {
    childList: true,
    subtree: false,
    attributes: false,
  }
) => {
  return new Promise(resolve => {
    if (target.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (target.querySelector(selector)) {
        resolve(target.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(target, observerOptions);
  });
};

export function isImageLoadComplete(imageElement) {
  return imageElement?.complete;
}
